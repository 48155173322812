import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { TextTitle } from '../../components/Typography';
import pictogram from '../../public/title-pictogram.svg';
import bi from '../../public/bi-darkblue.svg';
import bi_text from '../../public/bi-darkblue-textonly.svg';
import check from '../../public/signup-check.svg';

export const IntroImage = () => (
  <Overlayed>
    <Logo>
      <Image src={pictogram} alt="pictogram" width="171" height="171" />
      <Image src={bi} alt="pictogram" width="171" height="171" />
      <Image src={pictogram} alt="pictogram" width="171" height="171" />
    </Logo>
    <Title>
      <Image src={bi_text} alt="title" width="360" height="70%" />
      <div>대전신세계 ART & SCIENCE</div>
    </Title>
  </Overlayed>
);

export const SuccessImage = () => (
  <SignupSuccess>
    <div style={{ marginBottom: 40 }}>
      <Image src={check} width={177} height={177} alt="done_signup" />
    </div>
    <TextTitle size="24px" align="center">
      회원가입이
      <br />
      완료되었습니다
    </TextTitle>
  </SignupSuccess>
);

const Overlayed = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 24px;
  width: 100%;
  height: 100%;
`;

const Logo = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Title = styled.div`
  margin: 80px 0 0 0;
  text-align: center;

  div {
    color: #869ab8;
    font-size: 16px;
    letter-spacing: 0.1em;
  }
`;

const SignupSuccess = styled.section`
  padding: 100px 0 20px 0;
  margin: -30px 0 0 0;
  width: 100%;
  text-align: center;
  min-height: 287px;
  background: url('/confetti.png') no-repeat 50% 50%;
  background-size: 100%;
  img {
    width: 30%;
    min-width: 160px;
    margin: 0 0 40px 0;
  }
`;
